  .stats-tbl {
    table-layout: fixed;
    border-spacing: 0;
    margin-top: 2px;

    tr .cell {
      padding: 8px;
      border-top: 1px solid #238787;
      border-left: 1px solid #238787;

      &:last-child {
        border-right: 1px solid #238787;
      }

      &.no-border {
        border: none;
      }
    }

    tr .header-cell {
      padding: 8px;
      border-top: 1px solid #238787;

      &:last-child {
        border-right: 1px solid #238787;
      }

      &:first-child {
        border-left: 1px solid #238787;
      }

      &.no-border {
        border: none;
      }
    }

  }

  .arc-stats-tbl {
    tbody tr {
      &:first-child {
        .cell {
          color: black !important;
        }
      }

      .cell {
        &:not(:first-child) {
          border-left: none;
        }
        &:nth-child(2), &:nth-child(3) , &:nth-child(4){
          color: black;
          background-color: #DFF;
        }
      }

      &:last-child {
        .cell {
          border-bottom: 1px solid #238787;
        }
      }

      &:nth-child(odd) {
        .cell {
          background-color: #DFF;
        }
      }
    }
  }
  .custom-select .ant-select-selection-item {
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.custom-select .ant-select-selection-placeholder {
    font-family: 'Exo 2', sans-serif;
    font-size: 14px;
    font-weight: 400;
}
.custom-border .ant-select-selector {
  border-radius: 0.2em !important;
}
.custom-select .ant-select-arrow {
  color: #1133CC;
  font-size: 16px;
}