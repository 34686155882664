.page-not-found {
  padding: 6rem 5rem 3.3rem;
  position: relative;
  align-items: center;

  .oval-404 {
    position: absolute;
    top: 30px;
    left: 49%;
    width: 45%;
    max-width: 854px;
    max-height: 563px;
    height: 50.9rem;
    object-fit: contain;
  }

  .oval-404-text {
    font-size: 46.9rem;
    line-height: 38rem;
    margin: 0;
    font-weight: 300;
  }

  .txt {
    color: #007373;
  }

  .clang {
    font-size: 3.2rem;
    font-weight: 700;
  }

  .txt-404 {
    font-size: 4.8rem;
    font-weight: 500;
  }

  .txt-info {
    color: #073c3c;
    font-size: 2.2rem;
    margin-top: 0.5em;
  }

  .last-line {
    margin-bottom: -10px;
  }

  .back-home {
    font-size: 2.2rem;
    // width: 180px;
    padding: 2px 0;

    span {
      margin: 0;
    }
  }

  .oval-container {
    height: 100%;
    position: relative;
  }

  .text-container {
    padding-top: 64px;
  }

  .term-container {
    position: absolute;
    bottom: 3.3rem;

    .text {
      color: #0033cc;
      text-transform: capitalize;
      font-weight: normal;
    }
  }

  .divider {
    font-size: 14px;
    height: 20px;
    border-color: #707070;
    margin: 0 4px;
  }
}

ul {
  padding-inline-start: 30px !important;
}

ul li {
  padding-left: 10px !important;
}
