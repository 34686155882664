.doc-table {
  .cell {
    width: 100%;
  }

  .no-text-flow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .cel-circle {
    width: 12px;
    height: 12px;
    margin-right: 0.8rem;
    border-radius: 50%;
  }
}

.ant-table-wrapper {

  .ant-table-thead,
  .ant-table-tbody>tr {

    td,
    th {
      border-bottom: 0.5px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.table-ct {
  .page-change {
    margin: 16px 0 0 0 !important;
  }
}

.data-table-search {
  padding: 0.8rem;

  .input {
    margin-bottom: 0;
  }

  .label-cont {
    display: none;
  }

  .reset {
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    margin: 0 0.8rem;
  }
}

.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}

.truncate-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  /* Hides any content that overflows the container */
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 90%;
}

.truncate-ellipsis:hover {
  max-width: none;
  /* Remove the max-width on hover */
  white-space: wrap;
  text-overflow: auto;
  display: block;
}

.mb-title {
  margin-bottom: 20px;
}

.mb-stats {
  margin-bottom: -18px;
}

.mr {
  margin-right: 5px;
}

.export-page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 10px;
  padding-right: 200px;
}

.export-top-left-logo {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 0px;
}

.export-top-right-logo {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0px;
}

.export-bottom-left-logo {
  position: absolute;
  bottom: 0px;
  left: 0px;
  margin-left: -5px;
}

.export-bottom-right-logo {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.export-bottom-page-no {
  position: absolute;
  bottom: 0px;
  left: 45%;
  padding-bottom: 15px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: end;
}

.export-center-content {
  padding-top: 80px;
}

.continuity-table {
  position: relative;
  min-width: 1000px;
  min-height: 700px;
}

.ant-pagination-item {
  border: none !important;
  background-color: transparent !important;
}

.ant-pagination-item-active {
  .page-item {
    background-color: #1133CC;

    .page-no,
    .non-antd-icon {
      color: #fff;
    }
  }
}

.ant-pagination-disabled {
  .page-item {
    border-color: #D9D9D9;
  }
}

.page-item {
  border: 1px solid #1133CC;
  border-radius: 2px;
  min-width: 32px;
  height: 32px;
  padding: 0 6px;

  .non-antd-icon,
  .page-no {
    color: #1133CC;
  }
}

.doc-table .ant-table-thead>tr>th {
  position: relative;
}

.doc-table .ant-table-thead>tr>th::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1px;
  background-color: #D9D9D9;
  top: 20%;
  bottom: 20%;
}

.doc-table .ant-table-thead>tr>th:last-child::after {
  background-color: transparent;
}

.ant-pagination-item-active .page-item .recent-icon {
  background-image: url('../../assets/images/icon-recent-negative.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 28px;
  height: 23px;
}

.page-item .recent-icon {
  background-image: url('../../assets/images/icon-recent-action.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 28px;
  height: 23px;
}

.page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.doc-table .ant-table-body {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}