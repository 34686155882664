@font-face {
  font-family: 'icomoon';
  src: url('lib/icomoon.eot?vgxgdn');
  src: url('lib/icomoon.eot?vgxgdn#iefix') format('embedded-opentype'),
    url('lib/icomoon.ttf?vgxgdn') format('truetype'),
    url('lib/icomoon.woff?vgxgdn') format('woff'),
    url('lib/icomoon.svg?vgxgdn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user-check:before {
  content: "\e903";
}

.icon-add:before {
  content: "\e900";
}

.icon-attachment:before {
  content: "\e901";
}

.icon-contents:before {
  content: "\e902";
}

.icon-export:before {
  content: "\e904";
}

.icon-file-choose:before {
  content: "\e905";
}

.icon-file-csv:before {
  content: "\e906";
}

.icon-link-new-tab:before {
  content: "\e907";
}

.icon-presentations:before {
  content: "\e908";
}

.icon-recent:before {
  content: "\e909";
}

.icon-simulation:before {
  content: "\e90a";
}

.icon-stats:before {
  content: "\e90b";
}

.icon-variables:before {
  content: "\e90c";
}

.icon-warning-icon:before {
  content: "\e90d";
}