.neu-menus {
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;

  .sec-icon {
    width: 20px;
    margin-right: 8px;
  }

  &.single {
    .ant-dropdown-menu {
      border-radius: 0;
      padding: 4px;

      .ant-dropdown-menu-item {
        padding: 4px;
        cursor: text;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.neu-menus.custom-menu {
  ul {
    padding-inline-start: 0px !important;
  }
}

.task-menus {
  ul {
    padding-inline-start: 0px !important;
  }
}
.responder-dropdown {
  ul {
    padding-inline-start: 0px !important;
    padding-inline-end: 0px !important;
  }
}
