.details-form-input {
  margin-bottom: 1px !important;
}

.form-input {
  margin-bottom: 16px;

  .details-text-area {
    height: auto;
    line-height: 1.57;
    overflow: hidden;
  }

  .label {
    word-wrap: break-word;
  }

  &.no-label {
    .ant-form-item-label {
      display: none;
    }
  }

  .ant-form-item-label > label {
    height: 100%;
  }

  .w-120pix {
    width: 120px;
  }

  .w-100pix {
    min-width: 100px;
  }

  .w-90pix {
    min-width: 90px;
  }

  .w-70pix {
    width: 70px;
  }

  .w-60pix {
    width: 60px !important;
  }

  .req {
    position: relative;

    &::before {
      display: none !important;
    }

    &::after {
      position: relative;
      display: inline-block;
      color: #ff4d4f;
      font-size: 1.4rem;
      line-height: 1;
      content: "*" !important;
      margin-left: 3px;
    }
  }

  &.type-file {
    .select-btn {
      min-width: 90px; //changed from 100px
      max-height: 36px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.type-checkbox {
    .ant-checkbox-wrapper-checked:hover {
      .ant-checkbox-inner {
        background-color: #ff0000;
        border-color: #ff0000;
      }
    }

    .ant-checkbox-inner {
      background-color: #ff0000 !important;
      border-color: #ff0000 !important;
    }
  }

  &.type-text,
  &.type-search {
    .ant-form-item-required {
    }
  }

  .border {
    // border: 1px solid #D9D9D9;
    border-radius: 2px;
  }

  .textarea-input {
    resize: none;

    &.read-only {
      border: none;
      padding: 4px 11px;
    }
  }

  .select-input .ant-select-selector {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  .select-input {
    // padding-left: 8px;
    height: 36px;

    &.multiple {
      .ant-select-selector {
        padding: 0 11px;
      }

      .ant-select-selection-overflow-item-rest {
        display: none !important;
      }

      .ant-select-selection-overflow-item:not(
          .ant-select-selection-overflow-item-suffix
        ),
      .ant-select-selection-overflow {
        width: 100%;
      }

      .ant-select-selection-overflow-item > span {
        display: inline-block;
        width: 100%;
      }

      &:not(.search) .ant-select-selection-overflow-item-suffix {
        display: none;
      }

      &.search {
        .ant-select-selection-search {
          margin-inline-start: 0;
        }

        &:not(.ant-select-open) {
          .ant-select-selection-overflow-item-suffix {
            display: none;
          }
        }

        &.ant-select-open {
          .tag-value {
            display: none;
          }
        }
      }
    }
  }

  .read-only {
  }

  .select-input .ant-select-selection-search-input {
    height: inherit;
  }

  .read-only {
    border: none;
    height: auto;

    &.select-input {
      .ant-select-selector {
        border: none;
        background: transparent;
        padding: 0;
      }

      .ant-select-arrow {
        opacity: 0;
      }
    }
  }

  .select-input .ant-select-selector,
  .text-input {
    outline: none;
    color: inherit;
    line-height: 12px;
    width: 100%;
    height: 36px;
    resize: none;
    background-color: transparent;
    margin-top: 0;

    &.read-only {
      border: none;
      height: auto;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #efefef;
    }

    .input-ele {
      padding: 4px 8px;
      background-color: transparent;
      color: inherit;
    }
    .evidence-file-input {
      align-items: flex-start !important;
    }
    &.disabled.read-only {
      background-color: transparent;
    }
  }

  .text-input {
    &.no-pad {
      padding: 0;
    }

    .input-suffix {
      padding: 0 4px;
      border-left: 1px solid #d9d9d9;
    }

    &:focus {
      outline: none;
      border-color: #d9d9d9;
    }
  }

  .file-list {
    max-height: 200px;

    .file-icon {
      margin-right: 8px;
    }

    .file-entry {
      // width: calc(100% - 60px);
      padding: 4px 8px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      margin-bottom: 8px;
    }

    .btn-delete {
      cursor: pointer;
      padding: 4px;
      align-self: unset;
      margin-left: 8px;
    }
  }

  .cke_editable {
    min-height: 90px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    background: #fff;
    outline: none;
  }

  .suffix-pass-input {
    .pass-suffix {
      width: 40%;
      height: 36px;
      border: 1px solid #d9d9d9;
      border-right: none;
      border-radius: 0 0 0 0;
      background-color: #fafafa;
    }

    .input-suffix {
      border: none;
      cursor: pointer;
    }
  }

  &.req-colon {
    .ant-form-item-required {
      margin-right: 8px;

      &::before {
        display: inline-block !important;
        content: ":" !important;
        color: inherit !important;
        position: absolute;
        left: 95%;
      }
    }
  }

  &.type-drag-drop-file {
    .drag-drop-card {
      .title {
        margin: 20px 0 2px 0;
      }
    }

    .ant-upload-drag {
      background-color: inherit;
      border: 1px solid #d9d9d9;
    }
  }
}

.dropdown-list {
  .option[aria-selected="true"] {
    background-color: transparent !important;
    color: #0033cc;
  }
}

.dropdown {
  border: 1px solid #d9d9d9;

  .icon {
    margin-right: 0.8rem;
  }

  &:focus,
  &:hover {
    outline: none !important;
    border: 1px solid #d9d9d9;
  }
}

.dropdown-item {
  .icon,
  .label {
    color: inherit;
  }

  .icon {
    margin-right: 0.8rem;
  }

  &.Mui-selected {
    background-color: transparent !important;
  }

  &.primary:hover {
    background-color: #fff !important;

    .icon,
    .label {
      color: #0033cc;
    }
  }
}

.ant-input-affix-wrapper .ant-input-suffix {
  border-left: none;
  /* Removes the vertical line */
}

.down-icon {
  color: #0033cc;
  margin-right: 8px;
}

.collapse-header {
  cursor: pointer;
  margin-top: 12px;

  .icon {
    margin-right: 8px; // Adjust spacing between icon and label
  }
}

.collapse-content {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;

  > * {
    flex: 1; // Each child component takes equal width
    padding: 0; // Optional padding between components
  }
}

.ant-option {
  height: 30px;

  .bullet {
    &.placeholder {
      background-color: transparent;
      border-color: #000;
    }
  }

  .option-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .extra-count {
    min-width: 22px;
    height: 22px;
    border-radius: 1px;
    margin-left: 8px;
    margin-right: 20px;
    border: 1px solid #1a32c4;
  }

  &.multi-option {
    .check-container {
      border: 1px solid transparent;
      border-radius: 1px;
      margin-right: 8px;
      padding: 0 3px;
      height: 24px;

      &.checked {
        border-color: #448ef7;
      }
    }
  }
}

.ant-select-item-option-selected {
  .ant-select-item-option-state {
    display: none;
  }
}

.select-popup {
  .ant-select-item-option {
    height: 38px;
    padding: 5px 6px;
  }

  &.multiple {
    .ant-select-item-option-selected {
      background-color: transparent;
    }
  }
}
.evidence-text {
  padding-left: 0px !important;
  // margin-top: -15px;
}
.evidence-text span {
  color: black !important;
  font-family: "Exo 2", sans-serif !important;
}
