.screen {
  background-color: rgba($color: #238787, $alpha: 0.05);
  &.white {
    background-color: #FFF;
  }
}

.loading-indicator .loading {
  width: 42px;
  height: 42px;
  margin-right: 8px;
}

.loading-indicator.small .loading {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.loading-indicator .loading-oval {
  width: 240px;
  height: 240px;
}

.loading-indicator.small .loading-oval {
  width: 64px;
  height: 64px;
}
.loading-modal{
  .ant-modal-content{
    background: transparent;
    box-shadow: none;
  }
  .msg{
    color: #FFF;
  }
  .loading-indicator{
    margin-top: 100px;
  }
}