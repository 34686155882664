.breadcrumb {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button {
  color: white;
  background-color: #1133cc;
  border-radius: 0px;
}

.variant-lite {
  background-color: transparent !important;
}

.ant-tooltip-inner {
  font-size: 12px;
  padding: 5px;
  line-height: 1.2;
}

.ant-tooltip-arrow {
  transform: scale(0.5);
}

.tracker {
  .btn-responder {
    width: 24px;
    height: 24px;
    padding: 0;
    margin-left: 2px;
    flex-shrink: 0;
  }

  .btn-title {
    flex-shrink: 0;
    /* Prevents the button from shrinking */
    width: 24px;
  }
}

.new-task-modal {
  .form {
    padding-top: 16px;

    .ant-form-item-label {
      width: 96px;
    }

    .due_date {
      margin-left: 8px;

      .ant-form-item-label {
        width: 86px;
      }
    }
  }

  .label-cont {
    min-width: 96px !important;

    .label {
      width: 90%;
      text-align: right;
    }
  }
}

.tracker-filter {
  width: 645px !important;
  position: absolute;
  z-index: 200;
  right: 2.4rem;
  top: 0;
  height: 52px;

  .search-btn {
    margin-left: 10px;
    width: 36px;
  }

  .field-type {
    width: 177px;
  }

  .field-search {
    width: 403px;
    margin-left: 20px;
  }

  .form-input {
    margin-bottom: 0;
  }
}

.view-task {
  .header {
    padding: 9px 26px 16px 26px;

    .form-input {
      &.field-priority {
        .ant-form-item-control {
          // width: 150px;
        }
      }

      &.type-select .ant-form-item-label {
        width: 68px;
      }
    }

    .gap {
      margin-left: 24px;
    }

    .form-input {
      margin: 0 24px 0 0;
      min-width: 186px;

      .text-input {
        max-height: 680px;
      }
    }

    .field-short_name .textarea-input {
      color: #238787;
    }

    .btn-menu {
      width: 32px;
      height: 32px;
      border-color: #1133cc;
    }

    .btn-back {
      padding: 0;
      margin-bottom: 9px;
      width: 4vw;
    }
  }

  .history-sec {
    width: 30%;
    // max-width: 30%;
    // min-width: 25vw;
    // max-width: 28vw;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    background: #fff;
    position: relative;
    z-index: 1;
    overflow-x: auto;
    overflow-y: auto;

    .header {
      height: 56px;
      padding: 0 24px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
    }

    .footer {
      padding: 10px 20px 28px 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.06);

      .btn-submit {
        position: absolute;
        bottom: 50px;
        right: 20px;
        width: 90px;
      }
    }

    .documents.documents .select-btn {
      min-width: 96px;
    }

    .comment-card {
      // margin-right: 15px;
      // .avator {
      //   min-width: 32px;
      // }

      .link-evd {
        padding: 0;
      }

      .comment-text {
        margin-right: 10px;
        margin-bottom: 0;
        margin-top: 4px;
      }
      .comment-text ul {
        margin-top: 7px !important;
        padding-inline-start: 1em !important;
      }
      .comment-text ul li {
        padding-left: 5px !important;
      }
      padding: 24px 20px 0px 20px;
    }

    .comment-sec {
      width: 350px;
    }

    .comment-sec .link-evd {
      margin: 4px 0px;
      display: flex;
      align-items: flex-start;
      text-align: left;
    }

    .label-line {
      line-height: 1.3;
    }
  }

  .details-sec {
    padding: 4px 48px 0 48px;

    .form-input .ant-form-item-label {
      width: 105px;

      label {
        height: fit-content;
      }
    }
  }
}

.task-responders-menu {
  z-index: 1149 !important;

  .ant-dropdown-menu-item {
    padding: 0 !important;
    border-radius: 2px;
  }

  .container {
    width: 330px;

    .user-info {
      min-height: 96px;
      padding: 16px 20px 20px 20px;
    }
  }

  .footer {
    box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
    padding: 8px 20px 16px 16px;
  }
}

.task-menus {
  z-index: 1149 !important;

  .ant-dropdown-menu {
    border-radius: 0;
    padding: 0;

    .ant-dropdown-menu-item {
      padding: 0;
      cursor: text;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .task-menu-content {
    width: 343px;
    padding: 8px 20px 8px 24px;
  }

  .entry {
    min-height: 32px;
    margin-top: 10px;

    .label-sec {
      width: 120px;
    }
  }

  .add-res {
    padding: 4px;
    width: 64px;
    margin-top: 8px;
  }

  .res-list {
    max-height: 190px;
  }

  .due-date-form {
    margin-right: 12px;
  }

  .form-input {
    margin-bottom: 0;

    .text-input {
      height: 32px;
    }
  }
}

.add-responder {
  .form-input {
    margin: 20px 0 0 0;
  }
}

.dept-trans {
  .form-input {
    margin: 20px 0 0 0;
  }

  .para {
    margin: 0;
  }

  .field-taskId {
    width: 180px;

    .ant-input-prefix {
      color: #f00;
    }

    .border {
      border-color: #f00;
    }
  }
}

.mb1 {
  margin-bottom: 15px;
}

.ml1 {
  margin-left: 20px;
}

.mr1 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr2 {
  margin-right: 24px;
}

.mr4 {
  margin-right: 40px;
}

.-mt1 {
  margin-top: -10px;
}

.p1 {
  margin: 5px;
}

.pr1 {
  padding-right: 10px;
}

.warning {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.upload-task {
  .header {
    height: 88px;
    padding-top: 8px;
    margin-bottom: 10px;

    .item {
      height: 40px;
    }
  }

  .form-input {
    margin: 0 0 0 0;
  }

  .step {
    margin-right: 4px;
  }

  .template-download {
    width: 407px;
    padding: 12px 20px;
    border: 1px solid #d9d9d9;

    .icon-file-csv {
      margin-right: 8px;
    }
  }

  .upload-sec {
    width: 68%;
    margin-left: 24px;

    .field-upload .ant-upload-btn {
      height: 150px;
    }
  }

  .result-sec {
    margin-top: 10px;

    .upload-result {
      min-width: 634px;
      max-width: 43%;
    }

    .result-loading {
      height: 196px;
    }

    .header {
      height: 46px;
      padding: 12px 16px 12px 5px;
      border: 1px solid #d9d9d9;
      margin-bottom: 10px;
    }

    &.upload-result {
      margin-right: 10px;
    }

    .result {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 0 8px;
    }

    .ant-table-thead .ant-table-cell {
      padding: 8px 10px !important;
    }

    .download-cell {
      margin: 0 auto;
    }
  }
}

.dash {
  .btn-menu {
    margin-left: 8px;
  }
}

.task-priority-text {
  &.emergency {
    color: #bb0000;
  }

  &.urgent,
  &.critical {
    color: #dd2222;
  }

  &.high {
    color: #ff5522;
  }

  &.medium {
    color: #ffaa55;
    -webkit-text-stroke: 0.1px #000;
  }

  &.low {
    color: #ffff88;
    -webkit-text-stroke: 0.1px #000;
  }

  &.minimal {
    color: #ffffbb;
    -webkit-text-stroke: 0.1px #000;
  }

  &.least {
    background-color: #ffffdd;
    -webkit-text-stroke: 0.1px #000;
  }
}

.priority-cell,
.priority-field-option {
  &.emergency .bullet {
    background-color: #bb0000;
  }

  &.urgent .bullet,
  &.critical .bullet {
    background-color: #dd2222;
  }

  &.high .bullet {
    background-color: #ff5522;
  }

  &.medium .bullet {
    background-color: #ffaa55;
    border-color: #000;
  }

  &.low .bullet {
    background-color: #ffff88;
    border-color: #000;
  }

  &.minimal .bullet {
    background-color: #ffffbb;
    border-color: #000;
  }

  &.least .bullet {
    background-color: #ffffdd;
    border-color: #000;
  }
}

.task-status-text {
  &.future {
    color: #000088;
  }

  &.hold {
    color: #ff4444;
  }

  &.duplicate,
  &.redundant,
  &.abandoned,
  &.skipped,
  &.invalid {
    color: #888;
  }

  &.pending {
    color: #ff8888;
    -webkit-text-stroke: 0.1px #000;
  }

  &.open {
    color: #ffaaaa;
    -webkit-text-stroke: 0.1px #000;
  }

  &.started {
    color: #ffff88;
    -webkit-text-stroke: 0.1px #000;
  }

  &.review {
    color: #ff8800;
    -webkit-text-stroke: 0.1px #000;
  }

  &.resolved,
  &.remediated {
    color: #66cc66;
  }

  &.complete,
  &.closed {
    color: #228822;
  }

  &.failed {
    color: #dd2222;
  }
}

.status-cell,
.status-field-option {
  &.future .bullet {
    background-color: #000088;
  }

  &.hold .bullet {
    background-color: #ff4444;
  }

  &.duplicate .bullet,
  &.redundant .bullet,
  &.abandoned .bullet,
  &.skipped .bullet,
  &.invalid .bullet {
    background-color: #888;
    border-color: #000;
  }

  &.pending .bullet {
    background-color: #ff8888;
    border-color: #000;
  }

  &.open .bullet {
    background-color: #ffaaaa;
    border-color: #000;
  }

  &.started .bullet {
    background-color: #ffff88;
    border-color: #000;
  }

  &.review .bullet {
    background-color: #ff8800;
    border-color: #000;
  }

  &.resolved .bullet,
  &.remediated .bullet {
    background-color: #66cc66;
  }

  &.complete .bullet,
  &.closed .bullet {
    background-color: #228822;
    border-color: #000;
  }

  &.failed .bullet {
    background-color: #dd2222;
  }
}

.shadow-box-arc {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
  height: 3px;
  margin: -15px 0px 12px 0px;
}

.details-page-header {
  .field-status .ant-form-item-label {
    width: 61px !important;
  }
}

.textarea-input.details-text-area.read-only {
  padding: 0 !important;
  /* Overrides the default padding in read-only mode for specific fields */
}

.new-task-form {
  .field-name .ant-form-item-label,
  .field-priority .ant-form-item-label,
  .field-department .ant-form-item-label,
  .field-source .ant-form-item-label,
  .field-desc .ant-form-item-label {
    width: 125px !important;
  }

  .field-desc .ant-form-item-label {
    height: 20%;
  }

  .field-responder .ant-form-item-label {
    width: 115px !important;
  }

  .field-due_date .ant-form-item-label {
    width: 100px !important;
  }

  .field-plan_date .ant-form-item-label {
    width: 35px;
  }

  .field-start_date .ant-form-item-label {
    width: 90px !important;
  }

  .field-repetition_occurs .ant-form-item-label {
    width: 88px !important;
    padding-left: 12px;
  }

  .field-end_date .ant-form-item-label {
    width: 82px !important;
    padding-left: 18px;
  }

  .ant-form-item-label {
    margin-bottom: 17px;
  }
}

.export-task-form {
  .ant-option .option-text {
    height: 25px;
    display: flex;
    align-items: center;
  }

  .ant-form-item-label {
    margin-bottom: 17px;
  }

  .field-priority .ant-form-item-label,
  .field-status .ant-form-item-label,
  .field-range .ant-form-item-label,
  .field-source .ant-form-item-label {
    width: 70px !important;
  }

  .field-activity_type .ant-form-item-label,
  .field-department .ant-form-item-label,
  .field-responder .ant-form-item-label {
    width: 105px !important;
  }
}

.responder-table .ant-table-thead > tr > th {
  border-bottom: 0.3px solid #d9d9d9 !important;
}

.export-dialog-helptext {
  line-height: 1.2;
  margin-bottom: 15px;
}

.pr4 {
  padding-right: 40px !important;
}

.pr5 {
  padding-right: 50px !important;
}

.title-cell-container {
  max-width: 100%;
  overflow: hidden;
}

.title-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-cell.expanded {
  white-space: normal;
  overflow: visible;
}

.info-icon {
  margin-right: 5px;
  color: #888888;
  font-size: 14px;
  cursor: pointer;
}

.custom-tooltip .ant-tooltip-inner {
  opacity: 0.8;
  padding: 5px;
  border-radius: 0px;
  width: 433px;
  transform: translateX(-60%);
  text-align: left;
}

.custom-tooltip .ant-tooltip-arrow {
  left: 20%;
}

.custom-tooltip2 .ant-tooltip-inner {
  opacity: 0.8;
  padding: 5px;
  border-radius: 0px;
  width: 433px;
  transform: translateX(6%);
  text-align: left;
}

.avator {
  width: 34px;
  min-width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: #ddd;
  border: 0.5px solid #222222;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .avator {
    width: 28px;
    height: 28px;
    min-width: 28px;
  }
}

@media screen and (max-width: 480px) {
  .avator {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }
}

.change-res-modal .ant-input-affix-wrapper .input-suffix {
  background-color: #fafafa !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 0px !important;
  padding: 5px 10px !important;
}

.change-res-modal .ant-input-affix-wrapper .ant-input-suffix {
  height: 100%;
}

.change-res-modal .ant-input-affix-wrapper {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-right: 0 !important;
}

.change-res-modal .ant-modal-body {
  padding-bottom: 0px !important;
}

.change-res-modal .ant-modal-header {
  padding-bottom: 0px !important;
}
.deactv-usr {
  color: #ea3323;
  font-size: 12px;
  font-weight: 400;
  margin-left: 40px;
}
