.basic-btn {
  appearance: none;
  // padding: 8px 16px;
  border-radius: 2px;
  &.btn-text{
    border: 1px solid #1133CC;
  }
  &:disabled {
    background-color: #D9D9D9;
    cursor: not-allowed;
    .anticon {
      color: #FFF !important;
    }

    .label {
      color: #FFF !important;
    }
  }
  .label{
    line-height: 1.1;
    font-family: 'Exo 2', sans-serif !important;
  }
  &.ant-btn-text {
    &.non-label {
      &:disabled {
        background-color: transparent;
        .anticon {
          color: #D9D9D9 !important;
        }
      }
    }
  }


  .ant-btn-icon {
    margin: 0 2px 0 0 !important;
  }

  &.btn-menu {
    border: 1px solid #1133CC;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    height: 32px;
    width: 32px;
    padding: 0;

    .ant-btn-icon {
      // margin: 4px auto 0 auto !important;
      margin: 0 !important;
    }
  }

  &.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    background: transparent;
  }
}