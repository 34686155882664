.cFFF {
  color: #FFF;
}

.cFE3333 {
  color: #FE3333;
}

.cFF4444 {
  color: #FF4444;
}

.cc0000 {
  color: #cc0000;
}

.cF00 {
  color: #F00;
}

.c0133CC {
  color: #0133CC !important;
}

.c1133CC {
  color: #1133CC;
}

.c777777 {
  color: #777777;
}

.c1155CC {
  color: #1155CC;
}

.c00085 {
  color: rgba(0, 0, 0, 0.85);
}

.c00045 {
  color: rgba(0, 0, 0, 0.45);
}

.c00025 {
  color: rgba(0, 0, 0, 0.25);
}

.c00073 {
  color: rgba(0, 0, 0, 0.73)
}

.c000 {
  color: #000;
}

.c999 {
  color: #999999;
}

.cBFBFBF {
  color: #BFBFBF;
}

.c1890FF {
  color: #1890FF;
}

.c444444 {
  color: #444444;
}

.cBFBFBF {
  color: #BFBFBF;
}

.cBB2222D9 {
  color: #BB2222D9;
}

.cC3C3C3 {
  color: #C3C3C3;
}

.c989898 {
  color: #989898;
}

.published {
  color: #228822;
}

.inprogress {
  color: #f77a50;
}

.bgOverall {
  background-color: #1E686D;
}

.c1E686D {
  color: #1E686D;
}

.c44BB44 {
  color: #4b4;
}

.c882222 {
  color: #882222;
}

.c777 {
  color: #777;
}

.cinherit {
  color: inherit;
}

.c0033CC {
  color: #0033CC;
}

.c238787 {
  color: #238787;
}

.cDDD {
  color: #DDD;
}

.cD9D9D9 {
  color: #D9D9D9;
}

.c1A32C4 {
  color: #1A32C4;
}
.cEF8E3F{
  color: #EF8E3F;
}
.bgFAFAFA {
  background-color: #FAFAFA;
}

.bg0133CC {
  background-color: #0133CC;
}

.bg1133CC {
  background-color: #1133CC;
}

.bgF5F5F5 {
  background-color: #F5F5F5;
}

.bg59A7AC {
  background-color: #59A7AC;
}

.bgFFF {
  background-color: #FFF;
}

.bgFFDDDD {
  background-color: #FFDDDD;
}

.bg238787 {
  background-color: #238787;
}

.bg882222 {
  background-color: #882222;
}

.bgTrans {
  background-color: transparent;
}

.bgDDFFFF {
  background-color: #DDFFFF;
}