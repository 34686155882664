.form-dialog {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  width: 54%;
  padding: 2.4rem;
  max-width: unset !important;
  margin: 0 !important;

  .title-sec {
    padding: 0;
  }

  .dialog-container {
    padding: 0;
  }

  .dialog-actions {
    padding: 2rem 0 0 0;
    box-shadow: inset 0px 1px 0px #F0F0F0;
  }
}

.transparent-dialog {
  max-width: unset !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.neu-modal {
  border-radius: 1px;

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-modal-header {
    padding: 20px 0px 8px 20px;
  }

  .ant-modal-footer {
    margin-top: 0;
    padding: 10px 20px 20px 20px;
    box-shadow: 0px 1px 0px 0px #F0F0F0 inset;

    .basic-btn:nth-child(2) {
      margin-left: 8px;
    }
  }

  .ant-modal-body {
    padding: 0px 20px 20px 20px;
  }

  .ant-modal-content {
    padding: 0;
    border-radius: 1px;
  }

  &.mid {
    top: 50%;
    transform: translateY(-50%);
  }
}