@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,900;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

.exo2 {
  font-family: 'Exo 2', sans-serif !important;
}

* {
  font-family: 'Roboto', sans-serif;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}


.med {
  font-weight: 500;
}

.reg {
  font-weight: 400;
}

.bold-400 {
  font-weight: 400;
}

.bold-500 {
  font-weight: 500;
}

.bold-600 {
  font-weight: 600;
}

.bold-700 {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 300;
}

.f2 {
  font-size: 0.2rem;
}

.f3 {
  font-size: 0.3rem;
}

.f8 {
  font-size: 0.8rem;
}

.f9 {
  font-size: 0.9rem;
}

.f10 {
  font-size: 1rem;
}

.f12 {
  font-size: 1.2rem !important;
}

.f11 {
  font-size: 1.1rem;
}

.f13 {
  font-size: 1.3rem !important;
}

.f14 {
  font-size: 1.4rem;
}

.f15 {
  font-size: 1.5rem;
}

.f16 {
  font-size: 1.6rem;
}

.f18 {
  font-size: 1.8rem;
}

.f19 {
  font-size: 1.9rem;
}

.f20 {
  font-size: 2rem;
}

.f21 {
  font-size: 2.1rem;
}

.f22 {
  font-size: 2.2rem;
}

.f24 {
  font-size: 2.4rem;
}

.f28 {
  font-size: 2.8rem;
}

.f30 {
  font-size: 3rem;
}

.f16px {
  font-size: 16px;
}

.f14px {
  font-size: 14px;
}

.line-12 {
  line-height: 1.25;
}

.line-15 {
  line-height: 1.5;
}

.line-17 {
  line-height: 1.75;
}

.line-22 {
  line-height: 22px;
}

.line-20 {
  line-height: 20px;
}

.ml2 {
  margin-left: 20px;
}

/* xs: Small to large phone */
@media only screen and (max-width: 599px) {
  html {
    font-size: 6px;
  }
}

/* sm: Small to medium tablet */
@media only screen and (min-width: 600px) and (max-width: 959px) {
  html {
    font-size: 7px;
  }
}

/* md: Large tablet to laptop */
@media only screen and (min-width: 960px) and (max-width: 1263px) {
  html {
    font-size: 8px;
  }
}

/* lg: Desktop */
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
  html {
    font-size: 10px;
  }
}

/* xl: 4k and ultra-wide */
@media only screen and (min-width: 1903px) {
  html {
    font-size: 11px;
  }
}