@import "./styles/index.scss";

* {
  box-sizing: border-box !important;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.col {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
}

.row {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.v-ctr {
  align-items: center;
}

.self-ctr {
  align-self: center;
}

.h-ctr {
  justify-content: center;
}

.v-start {
  align-items: flex-start;
}

.v-end {
  align-items: flex-end;
}

.h-btn {
  justify-content: space-between;
}

.v-btn {
  align-items: space-between;
}

.h-arnd {
  justify-content: space-around;
}

.h-end {
  justify-content: flex-end;
}

.flex-full,
.f-rest {
  flex: 1;
}

.fwarp {
  flex-wrap: wrap;
}

.f-rest-2 {
  flex: 2;
}

.flex-grow-1 {
  flex-grow: 1;
}

.hide {
  display: none !important;
}

.o-hide {
  overflow: hidden;
}

.oy-auto {
  overflow: hidden auto;
}

.text-nxt {
  word-break: break-all;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.ant-app {
  font-size: inherit;
  font-family: "Roboto";
  color: inherit;
}

.w-100 {
  width: 100%;
}

.max-w-100 {
  max-width: 100%;
}

.w-90 {
  width: 90%;
}

.w-70 {
  width: 70%;
}

.w-65 {
  width: 65%;
}

.w-55 {
  width: 55%;
}

.w-49 {
  width: 49%;
}

.w-45 {
  width: 45%;
}

.w-39 {
  width: 39%;
}

.w-35 {
  width: 35%;
}

.w-33 {
  width: 33%;
}

.w-30 {
  width: 30%;
}

.w-20 {
  width: 18%;
}

.h-50 {
  height: 48%;
}

.h-85 {
  height: 85%;
}

.h-100 {
  height: 100%;
}

.pos-relative {
  position: relative;
}

.capital,
.caps {
  text-transform: capitalize;
}

.tup {
  text-transform: uppercase;
}

.txt-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.main-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.progress-cnt {
  position: absolute;
}

.api-progress {
  position: relative !important;
  visibility: hidden;

  &.show {
    visibility: visible;
  }
}

.screen-pad {
  padding: 1.6rem 2.4rem;
}

.screen-pad-tb {
  padding-bottom: 1.6rem;
}

.screen-pad-lr {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.doc-nav {
  background: #ddffff;
  width: 4.6rem;
  padding-top: 10.2rem;
  transition: width 0.4s ease-in-out;

  &:hover {
    width: 14rem;
  }

  .item {
    width: 100%;
    height: 4.8rem;
    margin-bottom: 8px;
    padding-left: 1.4rem;
    background-color: transparent;

    .icon {
      margin-right: 1.6rem;
    }

    &.active {
      background-color: #173436;
    }
  }
}

.txt-justify {
  text-align: justify;
}

.txt-right {
  text-align: right;
}

.ck-container {
  p {
    margin: 0;
  }

  .title {
    margin-bottom: 0.8rem;
  }

  * {
    box-sizing: unset !important;
  }
}

p.ck-container {
  margin: 0;
  font-size: unset;
  font-family: unset;

  &.max {
    max-height: 180px;
  }
}

.no-access {
  padding: 8rem;

  .btn {
    align-self: unset;
    margin-top: 3rem;
  }

  .title {
    font-size: 10rem;
    font-style: italic;
    color: #238787;
  }

  .text-sec {
    width: 60%;
    padding-top: 11rem;
  }

  .img-sec {
    width: 40%;
  }

  .eval {
    max-width: 400px;
    max-height: 600px;
  }
}

.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}

.ant-table-filter-column {
  .ant-table-filter-trigger {
    margin-right: 8px;
  }
}

.ant-table-thead .text-center {
  text-align: center !important;
}

.ant-table-body {
  .ant-table-cell {
    vertical-align: top;
  }

  .btn {
    padding: 0;
  }
}

.ant-table-cell {
  padding: 1rem 16px !important;
}

.ant-dropdown {
  z-index: 1300;
}

.select-popup {
  padding: 4px 0;
  background-color: #fff !important;

  .ant-select-item-option {
    border-radius: 0;
  }
}

.select-no-found {
  border-radius: 1px;
  padding: 4px;
}

.cke_toolbar {
  * {
    box-sizing: unset !important;
    font-family: unset;
  }

  p:first-child {
    // display: none;
  }

  &.tbl_of_content {
    p {
      width: 100%;
      clear: both;

      span:nth-child(2) {
        float: right;
      }
    }
  }
}

.cke_editable {
  padding: 0 4px;
}

.new-content {
  .header {
    padding-bottom: 1.6rem;

    .title-input {
      margin: 0;
      width: 60rem;

      .label-cont {
        display: none;
      }

      .container {
        padding-left: 0;
      }
    }

    .btn:nth-child(2) {
      margin: 0 1.6rem;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.ant-table-placeholder .ant-table-cell {
  border-bottom: none;
}

.no-scroll-bar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.ant-tabs-nav::before {
  border-bottom-color: transparent !important;
}

.pdf-view-container {
  // width: 100%;
  width: 1020px;
  margin: 0 auto;
}

.cke_notification.cke_notification_warning {
  display: none;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-4 {
  margin-top: 40px;
}

.no-wrap {
  white-space: nowrap;
}

.logo {
  height: 45px;
  max-width: 350px;
  object-fit: cover;
}

.small_logo {
  max-height: 27px;
  max-width: 150px;
  object-fit: contain;
}

.bullet {
  width: 14px;
  height: 14px;
  border-radius: 12px;
  margin-right: 8px;
  border: 0.5px solid transparent;
  background-color: #d9d9d9;
}
